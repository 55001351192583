import React from 'react';
import { suiLogo } from '../Assets';

const PoweredBySui = () => {
  return (
    <div className="fixed bottom-2 right-4 bg-white text-[#2F0836] rounded-full px-4 py-2 flex items-center shadow-lg z-50">
      <img src={suiLogo} alt="Sui Logo" className="w-5 h-5 mr-2" />
      <span className="text-sm font-medium">Powered by Sui</span>
    </div>
  );
};

export default PoweredBySui;