// src/pages/Home.js
import React from "react";
import { motion } from "framer-motion";
import {
  aboutImage,
  heroBackground,
  heroImage,
  tokenomicsImage,
} from "../Assets";
import { FAQ, Timeline } from "../Components";

const Home = () => {
  return (
    <div className="text-white">
      {/* Hero Section */}
      <section
        id="hero"
        className="lg:h-screen w-full relative flex flex-col lg:flex-row items-center justify-between px-6 md:px-12 lg:px-24 text-white pt-40 " // Added pt-20 for navbar space
        style={{
          backgroundImage: `url(${heroBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Left Column: Text and Button */}
        <div className="flex flex-col items-start space-y-4 md:space-y-6 max-w-lg">
          {/* Badge */}
          <div className="bg-[#FFE2A7] text-[#2F0836] rounded-full px-4 py-2 text-sm font-semibold">
            4M+ Players
          </div>

          {/* Main Heading */}
          <h1 className="text-3xl md:text-5xl font-metavers font-bold leading-tight">
            The first AI-powered Memecoin and Telegram game on Sui Network
          </h1>

          {/* Button with Telegram Link */}
          <a
            href="https://t.me/Aidogs_bot"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-4 bg-[#FFE2A7] text-[#2F0836] px-6 py-3 rounded-lg text-lg hover:bg-[#ffd997] transition-colors duration-200"
          >
            Launch App
          </a>
        </div>

        {/* Right Column: Hero Image */}
        <motion.img
          src={heroImage}
          alt="Hero Center"
          className="h-auto lg:h-3/4 w-auto object-contain order-last lg:order-none mt-8 lg:mt-0 lg:self-end" // Image is below text until lg screen size
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        />
      </section>

      {/* About Us Section */}
      <motion.section
        id="about"
        className="py-20 text-center"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-4xl md:text-5xl font-metavers text-[#FFE2A7]">
          About Us
        </h2>

        <p className="mt-4 max-w-xl mx-auto">
          Our team has crafted a top-tier Telegram platform with over 4 million
          dawgs, leveraging interactive experiences and onboarding fresh users
          into crypto. Now, we’re taking it to the next level by bringing our
          community on-chain with $AIDOGS.
        </p>
        {/* Statistics Section */}
        <div className="mt-12 max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
          <div className="bg-[#2F0836] border border-[#FFE2A7] p-6 rounded-lg text-center">
            <h3 className="text-lg font-bold text-[#FFE2A7]">Total Users</h3>
            <p className="text-3xl font-metavers mt-2 text-white">4M</p>
          </div>

          <div className="bg-[#2F0836] border border-[#FFE2A7] p-6 rounded-lg text-center">
            <h3 className="text-lg font-bold text-[#FFE2A7]">
              Daily Active Users (DAU)
            </h3>
            <p className="text-3xl font-metavers mt-2 text-white">200K</p>
          </div>

          <div className="bg-[#2F0836] border border-[#FFE2A7] p-6 rounded-lg text-center">
            <h3 className="text-lg font-bold text-[#FFE2A7]">
              Monthly Active Users
            </h3>
            <p className="text-3xl font-metavers mt-2 text-white">1.3M+</p>
          </div>
        </div>

        {/* Why Sui Section */}
        {/* Why Sui Section */}
        <div className="mt-10 bg-[#FFE2A7] rounded-lg p-6 shadow-lg max-w-2xl mx-auto text-[#2F0836]">
          <h3 className="text-2xl font-metavers mb-6">Why Sui Network?</h3>

          {/* Boxed Items */}
          <div className="grid gap-4 md:grid-cols-3">
            <div className="bg-[#FFE2A7] rounded-md p-4 shadow-md text-center border border-b-4 border-[#2F0836]">
              <h4 className="font-semibold mb-2">Scalability & Speed</h4>
              <p className="text-sm">
                High-speed transactions for a seamless experience.
              </p>
            </div>

            <div className="bg-[#FFE2A7] rounded-md p-4 shadow-md text-center border border-b-4 border-[#2F0836]">
              <h4 className="font-semibold mb-2">Low Fees</h4>
              <p className="text-sm">Cost-effective interactions for users.</p>
            </div>

            <div className="bg-[#FFE2A7] rounded-md p-4 shadow-md text-center border border-b-4 border-[#2F0836]">
              <h4 className="font-semibold mb-2">Future-Proof Design</h4>
              <p className="text-sm">
                Built for innovation and long-term growth.
              </p>
            </div>
          </div>
        </div>

        {/* About Image */}
        <img
          src={aboutImage}
          alt="About Us"
          className="mt-8 mx-auto rounded-lg md:max-w-lg lg:max-w-xl"
        />
      </motion.section>

      {/* Tokenomics Section */}
      <motion.section
        id="tokenomics"
        className="py-20 text-center"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-4xl md:text-5xl font-metavers text-[#FFE2A7]">
          Tokenomics
        </h2>
        <p className="mt-4 max-w-xl mx-auto">Max Supply: 10,000,000, 000</p>

        {/* Tokenomics Percentages */}
        <div className="flex justify-center space-x-6 mt-8 flex-wrap ">
          {[
            { percentage: "80%", label: "Airdrop & Community Incentives" },
            { percentage: "15%", label: "Liquidity Pool & Staking" },
            { percentage: "5%", label: "Reserves & Partnerships" },
          ].map((item, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center mb-8" // Added margin-bottom for spacing
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.1 }} // Delay for staggered effect
            >
              <div className="w-20 h-20 font-metavers flex items-center justify-center rounded-full bg-transparent md:p-20 border border-[#FFE2A7] text-white text-2xl md:text-4xl font-bold">
                {item.percentage}
              </div>
              <p className="text-sm text-[#FFE2A7] mt-2">{item.label}</p>
            </motion.div>
          ))}
        </div>

        <img
          src={tokenomicsImage}
          alt="Tokenomics"
          className="mt-8 w-full object-cover h-64 md:h-full"
        />
      </motion.section>

      {/* Roadmap Section */}
      <motion.section
        id="roadmap"
        className="py-20 text-center"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-4xl md:text-5xl font-metavers text-[#FFE2A7]">
          Roadmap
        </h2>
        <p className="mt-4 max-w-xl mx-auto">
          Our roadmap outlines AIDOGS’ journey from foundational community
          building and token launch to governance through the Dawgs DAO,
          rewarding loyal holders, and expanding partnerships. Each phase builds
          towards a sustainable future, with long-term growth and rewards for
          our engaged community.
        </p>
        <Timeline />
      </motion.section>

      {/* FAQ Section */}
      <motion.section
        id="faq"
        className="py-20 text-center"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <FAQ />
      </motion.section>
    </div>
  );
};

export default Home;
