// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logo } from '../Assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faWallet } from '@fortawesome/free-solid-svg-icons';

export const menuItems = [ // Export menuItems
  { title: 'About', link: 'about' },
  { title: 'Tokenomics', link: 'tokenomics' },
  { title: 'Buy Now', link: 'buy' },
  { title: 'F.A.Q', link: 'faq' },
];

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Function to handle scroll event
  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
  };

  // Function to handle link click
  const handleLinkClick = (link) => {
    if (location.pathname === '/') {
      document.getElementById(link).scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      navigate('/');
      setTimeout(() => {
        document.getElementById(link).scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
    setIsMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed top-0 left-0 w-full z-20 font-metavers transition-colors duration-300 ${isScrolled ? 'bg-[#451A4D]' : 'bg-transparent'}`}>
      <div className="container mx-auto flex justify-between items-center py-4 px-4 md:px-8">
        {/* Logo Section */}
        <div className="flex items-center space-x-3">
          <img src={logo} alt="Logo" className="h-12 w-auto md:h-16" />
        </div>

        {/* Menu Items Section (Desktop) */}
        <div className="hidden md:flex space-x-12">
          {menuItems.map((item, index) => (
            <span 
              key={index}
              onClick={() => handleLinkClick(item.link)}
              className="text-white cursor-pointer hover:text-gray-400"
            >
              {item.title}
            </span>
          ))}
        </div>

        {/* Connect Button Section (Desktop) */}
        <button className="hidden items-center space-x-2 bg-white text-[#2F0836] py-2 px-4 rounded-lg hover:bg-gray-200">
          <FontAwesomeIcon icon={faWallet} className="text-[#2F0836]" />
          <span>Connect</span>
        </button>

        {/* Mobile Menu Toggle Button */}
        <div className="md:hidden">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-white">
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="lg" />
          </button>
        </div>
      </div>

      {/* Mobile Menu Dropdown */}
      {isMenuOpen && (
        <div className="md:hidden bg-[#451A4D] text-white text-center py-4 space-y-4">
          {menuItems.map((item, index) => (
            <span 
              key={index}
              onClick={() => handleLinkClick(item.link)}
              className="block cursor-pointer hover:text-gray-300"
            >
              {item.title}
            </span>
          ))}
          <button className="hidden items-center space-x-2 bg-white text-[#2F0836] py-2 px-4 rounded-lg mx-auto hover:bg-gray-200">
            <span>Connect</span>
            <FontAwesomeIcon icon="fa-solid fa-wallet" className="text-[#2F0836]" />
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;