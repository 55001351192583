// src/App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { FloatButton } from "antd";
import { Footer, Navbar, PoweredBySui } from "./Components";
import { Home, NoPage } from "./Pages";

function App() {
  return (
    <div className="App scroll-smooth bg-gradient-to-b from-[#0F0011] via-[#451A4D] to-[#71307D] px-4 py-4 md:px-8 md:py-6">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<NoPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>


      <FloatButton.BackTop />
      <PoweredBySui />
    </div>
  );
}

export default App;