// src/components/FAQ.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: 'What is AIDOGS?',
      answer: 'AIDOGS is a community-driven, AI-powered memecoin that combines the fun of meme culture with crypto, aiming to create an engaging, inclusive, and rewarding experience for all.',
    },
    {
      question: 'How can I participate in AIDOGS airdrop?',
      answer: (
        <div>
          Join our Telegram Bot, perform tasks, and claim daily to earn $AIDOGS.
          <div className="flex justify-center mt-4">
            <a
              href="https://t.me/Aidogs_bot"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex items-center space-x-2 bg-white text-[#2F0836] py-2 px-4 rounded-lg hover:bg-gray-200">
                <span>Join</span>
              </button>
            </a>
          </div>
        </div>
      ),
    },
    {
      question: 'Is AIDOGS financial advice?',
      answer: 'No, AIDOGS provides entertainment and educational content only. We are not financial advisors, and nothing on our platform should be considered financial advice.',
    },
    {
      question: 'What does AIDOGS do with my data?',
      answer: 'We do not collect personal information. Visit our Privacy Policy for details on your protection and security.',
    },
    {
      question: 'How can I stay updated on AIDOGS news?',
      answer: 'Follow us on our Socials to stay up to date.',
    },
    {
      question: 'When is the launch date for $AIDOGS?',
      answer: 'TBD',
    },
  ];

  return (
    <div className="text-white py-10 max-w-2xl mx-auto">
      <h2 className="text-4xl text-center font-metavers text-[#FFE2A7] mb-8">FAQ</h2>
      {faqItems.map((item, index) => (
        <div key={index} className="mb-4">
          <div
            className="cursor-pointer flex justify-between items-center bg-[#451A4D] p-4 rounded-lg"
            onClick={() => toggleAccordion(index)}
          >
            <h3 className=" text-md">{item.question}</h3>
            <span className="text-2xl">{activeIndex === index ? '-' : '+'}</span>
          </div>

          {activeIndex === index && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              transition={{ duration: 0.3 }}
              className="bg-[#71307D] p-4 rounded-b-lg text-white"
            >
              <p className="text-sm">{item.answer}</p>
            </motion.div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;