// src/components/Timeline.js
import React from 'react';

const Timeline = () => {
  const timelineItems = [
    {
      number: 1,
      title: 'Foundation & Community Building',
      text: '✅ Socials Setup: Build our presence on social media. \n✅ Telegram Bot: Launch gaming bot for community engagement. \n■ Exchange Partnerships: Establish listings on major exchanges.',
    },
    {
      number: 2,
      title: 'Token Launch',
      text: '■ Launch $AIDOGS Token: Enable trading within our ecosystem. \n■ Airdrop Claim: Open airdrop claim for eligible users.',
    },
    {
      number: 3,
      title: 'Dawgs DAO Development',
      text: '■ DDAO Launch: Empower community governance. \n■ Governance Proposals: Allow holders to vote on project decisions.',
    },
    {
      number: 4,
      title: 'Airdrop Rewards for Holders',
      text: '■ Airdrop Program: Reward loyal holders and partners. \n■ DAO Participation Rewards: Reward active governance members.',
    },
    {
      number: 5,
      title: 'Growth & Expansion',
      text: '■ Updates & Features: Regular improvements based on feedback. \n■ Partnerships: Expand reach through new collaborations.',
    },
    {
      number: 6,
      title: 'Future Vision',
      text: '■ Long-Term Rewards: Sustainable growth for the AIDOGS community.',
    },
  ];

  // Function to generate a random color
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <section id="timeline" className="py-20 text-center">
      <div className="relative flex flex-col items-center">
        
        {/* Vertical Divider */}
        <div className="absolute left-1/2 transform -translate-x-1/2 w-1 bg-[#451A4D] h-full" />

        {/* Timeline Items */}
        {timelineItems.map((item, index) => (
          <div
            key={index}
            className="relative z-10 flex flex-col items-center w-full max-w-xl my-1"
          >
            <div className="flex flex-col items-center">
              <div
                className="relative bg-white rounded-full w-20 h-20 flex items-center justify-center mb-2 border-8"
                style={{ borderColor: getRandomColor() }} // Random border color
              >
                <span className="text-[#71307D] font-metavers font-bold text-4xl">
                  0{item.number}
                </span>
              </div>
              <h3 className="text-[#FFE2A7] font-metavers font-semibold text-xl">{item.title}</h3>
              <p className="text-white text-sm whitespace-pre-line">{item.text}</p>
            </div>

            {/* Connector line between items */}
            {index < timelineItems.length - 1 && (
              <div className="w-1 h-20 bg-[#451A4D] my-1" />
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Timeline;